import Vue from 'vue';
import formFieldset from './formFieldset.jsx';
import formArray from './formArray.jsx';
import formTabArray from './formTabArray.jsx';
import formText from './formText.jsx';
import formPassword from './formPassword.jsx';
import formTextarea from './formTextarea.jsx';
import formCheckbox from './formCheckbox.jsx';
import formCheckboxes from './formCheckboxes.jsx';
import formRadios from './formRadios.jsx';
import formHelp from './formHelp.jsx';
import formParagraph from './formParagraph.jsx';
import formNumber from './formNumber.jsx';
import formMediaSelector from './formMediaSelector.jsx';
import formInputWithHeaderBar from './formInputWithHeaderBar.jsx';
import formTextareaWithHeaderBar from './formTextareaWithHeaderBar.jsx';
import formLookupList from './formLookupList.jsx';
import formSection from './formSection.jsx';
import formSelect from './formSelect.jsx';
import formDropdownMultiSelect from './formDropdownMultiSelect.jsx';
import formPhoneNumber from './formPhoneNumber.jsx';
import formDateTime from './formDateTime.jsx';
import formDate from './formDate.jsx';
import formTime from './formTime.jsx';
import formDatePicker from './formDatePicker.jsx';
import formDateRangePicker from './formDateRangePicker.jsx';
import formSingleDatePicker from './formSingleDatePicker.jsx';
import formEmail from './formEmail.jsx';
import formTabs from './formTabs.jsx';
import formConditional from './formConditional.jsx';
import formControlsWithLabel from './formControlsWithLabel.jsx';
import formBasicIconPicker from './formBasicIconPicker.jsx';
import formAnyOf from './formAnyOf.jsx';
import formActions from './formActions.jsx';
import formButton from './formButton.jsx';
import formIconPicker from './formIconPicker.jsx';
import formTags from './formTags.jsx';
import formTextEditor from './formTextEditor.jsx';
import formHtmlEditor from './formHtmlEditor.jsx';
import formReferencedDocumentSchema from './formReferencedDocumentSchema.jsx';
import tableLayout from './layoutTable.jsx';
import layoutCard from './layoutCard.jsx';

import formMissing from './formMissing.jsx';
import formUndefined from './formUndefined.jsx';

import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';
import filters from '../../../Shared/filters';
import careHelpfulFunctions from '../../careHelpfulFunctions.jsx';

Vue.component('schema-form', {
    data: () => ({
        masterkey: null,
        form_all: null,
    }),
    props: {
        name: '',
        root: null,
        schema: null,
        form: null,
        cmodel: null,
        noinput: null,
        scopeitems: null,
        controlscope: null,
        enable_watch: false,
    },
    watch: {
        schema: function (newvalue, oldvalue) {
            if (!this.schema || (this.schema && this.schema.type == 'object')) {
                if (this.schema)
                    utils.schema.logSchema('----SchemaForm schema changed, generating form_all: ', this.schema, 3);

                let form = this.form;
                if (!form || form.length == 0)
                    form = ['*'];
                else
                    // Make a deep copy to avoid changing the original
                    form = JSON.parse(JSON.stringify(this.form));

                this.form_all = utils.forms.generateFormWithSchema(form, this.schema);
                this.masterkey = utils.generateUUID();

                if (this.schema) {
                    utils.debug(`SchemaForm ${this.name} schema changed, merging in new schema into model...`);
                    //utils.forms.mergeDefaultModel(this.cmodel, this.schema, this.$parent); // Pass BasicForm as the context (it has all properties needed for interpolation)
                }

                this.$emit('formfinal', this.form_all, this);
            }
        }
    },
    created() {
        this.masterkey = utils.generateUUID();

        let form = this.form;
        if (!form || form.length == 0)
            form = []; // ['*'];
        else
            // Make a deep copy to avoid changing the original
            form = JSON.parse(JSON.stringify(this.form));

        if (!this.schema || (this.schema && (this.schema.type == 'object' || !this.schema.type))) {
            if (this.schema)
                utils.schema.logSchema('----SchemaForm created, generating form_all: ', this.schema, 3);

            this.form_all = utils.forms.generateFormWithSchema(form, this.schema);
            this.$emit('formfinal', this.form_all, this);
        }

        if (this.schema)
            utils.forms.mergeDefaultModel(this.cmodel, this.schema, this.$parent); // Pass BasicForm as the context (it has all properties needed for interpolation)

        if (this.enable_watch) {
            this.model_watch$ = this.$watch(
                function () { return this.cmodel; },
                function (newvalue) {
                    this.$emit('change', newvalue);
                },
                {
                    deep: true
                }
            );
        }
    },
    destroyed() {
        if (this.model_watch$)
            this.model_watch$();
    },
    methods: {
        applyFieldDefaults(schema, model, key, conditions, context) {
            //utils.debug(`+++ SchemaForm.applyFieldDefaults(key:${key})`);

            let s = schema;
            if (key in model) {
                // Data is already present in the model

                if (s.type == 'object')
                    // Recursively apply defaults to all properties of the object
                    this.applyObjectDefaults(s, model[key], conditions, context);
                else if (s.type == 'array') {
                    s = s.items;
                    for (let i = 0; i < model[key].length; i++) {
                        switch (s.type) {
                            case 'object':
                                if (!model[key][i] && !s.$allows_null)
                                    model[key][i] = {};

                                if (model[key][i])
                                    this.applyObjectDefaults(s, model[key][i], conditions, context);
                                break;

                            case 'array':
                                if (!model[key][i] && !s.$allows_null)
                                    model[key][i] = [];
                                break;

                            default:
                                if ((model[key][i] === undefined || model[key][i] === null) && 'default' in s)
                                    model[key][i] = s.default;
                                break;
                        }
                        //this.applyFieldDefaults(s, model, key, conditions, context);
                    }
                }
            }
            else {
                if (s.type == 'object') {
                    if (!s.$allows_null)
                        model[key] = {};

                    // Recursively apply defaults to all properties of the object
                    this.applyObjectDefaults(s, model[key], conditions, context);
                }
                else if (s.type == 'array') {
                    if (!s.$allows_null)
                        model[key] = [];
                }
                else if ('default' in s)
                    model[key] = s.default;
            }
        },
        applyObjectDefaults(schema, model, conditions, context) {
            //utils.debug(`+++ SchemaForm.applyObjectDefaults()`);

            for (let key in schema.properties) {
                let s = schema.properties[key];
                s = utils.schema.resolve_Of(s);

                if (!conditions && s.$$condition)
                    continue;
                else if (conditions && !s.$$condition)
                    continue;

                if (s.$$condition) {
                    context.ParentModel = model;

                    // new Function('context', 'util', 'with (context) return ' + s.condition + ';');
                    if (!s.$$condition(context, careHelpfulFunctions))
                        continue;
                }
                this.applyFieldDefaults(s, model, key, conditions, context);

                //if (key in model) {
                //    // Data is already present in the model

                //    if (s.type == 'object')
                //        // Recursively apply defaults to all properties of the object
                //        this.applyObjectDefaults(s, model[key], conditions, context);
                //    else if (s.type == 'array') {
                //        s = s.items;
                //        for (let i = 0; i < model[key].length; i++) {
                //            switch (s.type) {
                //                case 'object':
                //                    if (!model[key][i] && !s.$allows_null)
                //                        model[key][i] = {};

                //                    if (model[key][i])
                //                        this.applyObjectDefaults(s, model[key][i], conditions, context);
                //                    break;

                //                case 'array':
                //                    if (!model[key][i] && !s.$allows_null)
                //                        model[key][i] = [];
                //                    break;

                //                default:
                //                    if ((model[key][i] === undefined || model[key][i] === null) && 'default' in s)
                //                        model[key][i] = s.default;
                //                    break;
                //            }
                //            //this.applyFieldDefaults(s, model, key, conditions, context);
                //        }
                //    }
                //    continue;
                //}

                //if (s.type == 'object') {
                //    if (!s.$allows_null)
                //        model[key] = {};

                //    // Recursively apply defaults to all properties of the object
                //    this.applyObjectDefaults(s, model[key], conditions, context);
                //}
                //else if (s.type == 'array') {
                //    if (!s.$allows_null)
                //        model[key] = [];
                //}
                //else if ('default' in s)
                //    model[key] = s.default;
            }
        },
        generateDefaultModel() {
            // For all elements in schema, ignoring fields that have false conditions, generate
            // the default values in the model. As the model changes, the conditions may also
            // change. So the first pass will involve only fields without a condition. Then a
            // second pass will look at those with a condition. Following the second pass, a
            // third and final pass will check for any fields that may have true conditions
            // that did not the previous pass.
            const context = {
                ...methods,
                ...filters,
                FormModel: this.cmodel,
            };

            this.applyObjectDefaults(this.schema, this.cmodel, false, context);
        }
    },
    render() {
        if (!this.form_all || this.form_all.length === 0)
            return null;

        return (
            <v-form readonly={this.noinput} ref="form" on-submit={(e) => { e.preventDefault(); return false; }}>
                <sform-fieldset
                    layouttype="sform-card-layout"
                    root={this.root}
                    form={this.form_all}
                    cmodel={this.cmodel}
                    noinput={this.noinput}
                    schema={this.schema}
                    scopeitems={this.scopeitems}
                    controlscope={this.controlscope}
                    depth={0}>
                </sform-fieldset>
            </v-form>
        );
    }
});